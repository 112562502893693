import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"]

  toggle(event) {
    if (this.checkboxTarget.checked) {
      this.buttonTarget.classList.remove('is-disabled')
    } else { this.buttonTarget.classList.add('is-disabled') }
  }

}
